
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import { ExpertClient } from '@/services/Services';
import InfoModal from '@/components/modals/infoModal.vue';
import { createPreviewFromImage } from '@/utility';

@Options({})
export default class expertDetail extends Vue {

    expertIdentifier: string = "";
    expert: OM.ExpertVM = new OM.ExpertVM();
    file: File = null;
    filePreview: string = "";

    created(){
        this.expertIdentifier = this.$route.params.identifier as string;

        this.init();
    }

    init(){
        this.file = null;
        this.filePreview = "";

        if(!this.expertIdentifier)
            return;

        ExpertClient.getByID(this.expertIdentifier)
        .then( x => {
            this.expert = x;
        })
    }

    save(){
        var toSave: OM.SaveExpertVM = {
            identifier: this.expertIdentifier,
            informations: this.expert.informations,
            jobLabel: this.expert.jobLabel,
            cta: this.expert.cta,
            name: this.expert.name,
            socialLinks: this.expert.socialLinks,
            contactData: this.expert.contactData,
            podcastLinks: this.expert.podcastLinks, 
            orderPosition: this.expert.orderPosition
        };

        ExpertClient.save(toSave)
        .then( x => {
            ExpertClient.updatePhoto( x, this.file)
            .then( () => {
                this.expertIdentifier = x;
                this.$router.replace("/expert/" + x);
                this.init();
            })
        })
    }

    async imageChanged(file: File){
        if(!file)
            return;

		this.file = file;
		this.filePreview = await createPreviewFromImage(file);
	}

    get photoImage(){
        if(this.filePreview)
            return this.filePreview;

        if(this.expert.photoUrl)
            return this.expert.photoUrl;

        return "";
    }

    get isDisabled(){
        // !this.expert.contactData.phoneNumberComposition.phoneNumber || !this.expert.contactData.phoneNumberComposition.prefix ||
        return !this.expert.name.trim() || !this.expert.informations.trim() || !this.expert.contactData.emailAddress.trim() || (!this.expert.photoUrl && !this.file);
    }

    publishExpert(){
        if(!this.expertIdentifier)
            return;

        this.$opModal.show(InfoModal, {
            text: this.$localizationService.getLocalizedValue("backoffice_Attenzione", "Attenzione"),
            subtitle: this.$localizationService.getLocalizedValue("backoffice_SeiSicuroDiVolerPubblicareQuestoEsperto", "Sei sicuro di voler pubblicare questo Esperto?"),
            confirm: this.$localizationService.getLocalizedValue("backoffice_Si", "Si"),
            confirmCb: () => {
                this.$opModal.closeLast();
                this.publish();
            },
            deny: this.$localizationService.getLocalizedValue("backoffice_chiudi", "Chiudi"),
            denyCb: () => {
                this.$opModal.closeLast();
            }
        })
    }

    publish(){
        ExpertClient.publishByID(this.expertIdentifier)
        .then( x => {
            this.$opModal.show(InfoModal, {
                text: this.$localizationService.getLocalizedValue("backoffice_Successo", "Successo"),
                subtitle: this.$localizationService.getLocalizedValue("backoffice_PubblicatoConSuccesso", "Pubblicato con successo!"),
                confirm: this.$localizationService.getLocalizedValue("backoffice_chiudi", "Chiudi"),
                confirmCb: () => {
                    this.init();
                    this.$opModal.closeLast();
                }
            })
        })
    }

    deleteExpert(){
        if(!this.expertIdentifier)
            return;

        this.$opModal.show(InfoModal, {
            text: this.$localizationService.getLocalizedValue("backoffice_Attenzione", "Attenzione"),
            subtitle: this.$localizationService.getLocalizedValue("backoffice_SeiSicuroDiVolerEliminareQuestoEsperto", "Sei sicuro di voler eliminare questo Esperto?"),
            confirm: this.$localizationService.getLocalizedValue("backoffice_Si", "Si"),
            confirmCb: () => {
                this.$opModal.closeLast();
                this.delete();
            },
            deny: this.$localizationService.getLocalizedValue("backoffice_chiudi", "Chiudi"),
            denyCb: () => {
                this.$opModal.closeLast();
            }
        })
    }

    delete(){
        ExpertClient.deleteByID(this.expertIdentifier)
        .then( x => {
            this.$opModal.show(InfoModal, {
                text: this.$localizationService.getLocalizedValue("backoffice_Successo", "Successo"),
                subtitle: this.$localizationService.getLocalizedValue("backoffice_EliminatoConSuccesso", "Eliminato con successo!"),
                confirm: this.$localizationService.getLocalizedValue("backoffice_chiudi", "Chiudi"),
                confirmCb: () => {
                    this.$router.replace("/experts-list");
                    this.$opModal.closeLast();
                }
            })
        })
    }

    hideQuestion(){
        if(!this.expertIdentifier)
            return;

        this.$opModal.show(InfoModal, {
            text: this.$localizationService.getLocalizedValue("backoffice_Attenzione", "Attenzione"),
            subtitle: this.$localizationService.getLocalizedValue("backoffice_SeiSicuroDiVolerNascondereQuestoEsperto", "Sei sicuro di voler nascondere questo Esperto?"),
            confirm: this.$localizationService.getLocalizedValue("backoffice_Si", "Si"),
            confirmCb: () => {
                this.$opModal.closeLast();
                this.hide();
            },
            deny: this.$localizationService.getLocalizedValue("backoffice_chiudi", "Chiudi"),
            denyCb: () => {
                this.$opModal.closeLast();
            }
        })
    }

    hide(){
        ExpertClient.hideByID(this.expertIdentifier)
        .then( x => {
            this.$opModal.show(InfoModal, {
                text: this.$localizationService.getLocalizedValue("backoffice_Successo", "Successo"),
                subtitle: this.$localizationService.getLocalizedValue("backoffice_NascostoConSuccesso", "Nascosto con successo!"),
                confirm: this.$localizationService.getLocalizedValue("backoffice_chiudi", "Chiudi"),
                confirmCb: () => {
                    this.init();
                    this.$opModal.closeLast();
                }
            })
        })
    }

    addPodcast(){
        this.expert.podcastLinks.push(new OM.PodcastLink());
    }

    debouncer: any = null;
    loadPodcastData(item: OM.PodcastLink){
        clearTimeout(this.debouncer)
        if(!item.podcastUrl)
            return;

        this.debouncer = setTimeout(() => {
            ExpertClient.getYoutubePodcastLink(item.podcastUrl)
            .then(x => {
                item.podcastUrl = x.podcastUrl;
                item.thumbnailUrl = x.thumbnailUrl;
                item.timeLength = x.timeLength;
                item.title = x.title;
            })
        }, 400)
    }

    removePodcast(i: number){
        this.expert.podcastLinks.splice(i, 1);
    }

}
